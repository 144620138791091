import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import Img from 'gatsby-image'
import {Link} from 'gatsby'

const ParkPageTemplate = ({ title, notice, content, contentComponent, photos }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <PageContent className="content" content={content} />
            <Notice notice={notice} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="photo-gallery">
              { photos.map( photo => (
                < >
                  {photo.orientation === 'portrait' ? (
                    <a href={photo.source.publicURL} target="_blank" className="photo-gallery-portrait-item">
                      <Img fluid={photo.source.childImageSharp.portrait} alt={photo.description} style={{ position: 'relative' }} />
                    </a>
                  ) : (
                    <a href={photo.source.publicURL} target="_blank" className="photo-gallery-landscape-item">
                      <Img fluid={photo.source.childImageSharp.landscape} alt={photo.description} style={{ position: 'relative' }} />
                    </a>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ParkPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default ParkPageTemplate
