import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ParkPageTemplate from '../components/ParkPageTemplate'
import { HTMLContent } from '../components/Content'

const ParkPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ParkPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        notice={post.frontmatter.notice}
        photos={post.frontmatter.photos}
      />
    </Layout>
  )
}

ParkPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ParkPage

export const parkPageQuery = graphql`
  query ParkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
        photos {
          description
          source {
            id
            publicURL
            childImageSharp {
              landscape: fluid (maxWidth: 380, maxHeight: 190, cropFocus: ENTROPY ){
                ...GatsbyImageSharpFluid
              }
              portrait: fluid (maxWidth: 190, maxHeight: 380, cropFocus: ENTROPY){
                ...GatsbyImageSharpFluid
              }
            }
          }
          orientation
        }
      }
    }
  }
`
